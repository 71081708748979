<template>
  <!-- ID -->
  <SharedInputError v-if="isUserIdError">
    {{ t("errors.standoff_id") }}
  </SharedInputError>
  <Input
    v-model="standoffInputValueId"
    :has-error="isUserIdError"
    :placeholder="t('forms.order_user_data.input_standoff_id')"
    size="l"
    type="number"
    :class="$style['form-order-element']"
  />

  <SharedInputError v-if="isUserAvatarError">
    {{ t("errors.game_avatar") }}
  </SharedInputError>

  <!-- Email -->
  <SharedInputError v-if="isUserMailError && !userStore.userData?.email">
    {{ t("errors.input_user_mail") }}
  </SharedInputError>
  <Input
    v-if="!userStore.userData?.email"
    v-model="inputValueMail"
    :has-error="isUserMailError"
    :placeholder="t('errors.input_user_mail')"
    size="l"
    :class="$style['form-order-element']"
  />

  <p :class="$style['form-order-text']">
    {{ $t("forms.order_user_data.game_avatar") }}
  </p>
  <Uploader
    :has-limit="false"
    :default-imag="defaultSteamAvatar"
    @change="imgChange"
  />

  <div>
    <p :class="$style['form-order-text']">
      {{ $t("forms.order_user_data.skin") }}
    </p>
    <StandoffSkin
      v-if="settings"
      :src="settings?.skinPicture || ''"
      :description="settings?.skinName || ''"
    />
    <p :class="$style['form-order-text']">
      {{ $t("forms.order_user_data.skin_price") }}
    </p>
    <CopyField :value="skinPrice || 0" />
    <div style="margin-top: 10px">
      <GeneralError v-if="isError">
        {{ t("errors.order_error") }}
      </GeneralError>
      <GeneralError v-if="errorText">
        {{ errorText }}
      </GeneralError>
    </div>
  </div>

  <OrderBtnWrapper>
    <OrderBtn
      :content="`${t('products.products_order.buy')} ${toLocalNumber(productsStore.totalCost)} ₽`"
      @click="productsStore.orderStandoff"
    />
  </OrderBtnWrapper>
</template>

<script lang="ts" setup>
import Input from "~/components/Shared/Input/Input.vue";
import OrderBtnWrapper from "~/components/Products/OrderBtnWrapper.vue";
import OrderBtn from "~/components/Products/OrderBtn.vue";
import Uploader from "~/components/Shared/Uploader/Uploader.vue";
import CopyField from "./StandoffForm/CopyField.vue";
import GeneralError from "./GeneralError.vue";
import StandoffSkin from "./StandoffForm/StandoffSkin.vue";
import type { StandoffOrderSettings } from "~/api_gen";

const { t } = useI18n();
const productsStore = useProductsStore();
const userStore = useUserStore();
const {
  standoffInputValueId,
  inputAvatar,
  inputValueMail,
  isUserMailError,
  isUserIdError,
  isUserAvatarError,
  isError,
  errorText,
  surplus,
  defaultSteamAvatar,
} = storeToRefs(productsStore);

const settings = ref<StandoffOrderSettings>();

const sum = computed(() =>
  productsStore.gameData?.products.reduce(
    (res, { amount, data: { price } }) => res + amount * Number(price),
    0,
  ),
);

const skinPrice = computed(() => {
  if (!sum.value || !settings.value?.rate || settings.value?.rate <= 0) {
    return 0;
  }

  const convertedWithdrawSum = sum.value / settings.value?.rate;
  const commission = settings.value.marketFee * (convertedWithdrawSum || 0);
  const skinPrice = useBankersRounding(
    convertedWithdrawSum + commission + surplus.value,
  );

  return skinPrice;
});

productsStore.initOrderFormValues();

const data = await productsStore.orderStandoffSettings();
if (data) {
  settings.value = data;
}

const imgChange = (file: File) => (inputAvatar.value = file);

onUnmounted(() => {
  standoffInputValueId.value = "";
  inputAvatar.value = null;
  isUserMailError.value = false;
  isUserIdError.value = false;
  isUserAvatarError.value = false;
  isError.value = false;
  errorText.value = "";
});
</script>

<style lang="scss" module>
.form-order-element {
  margin-bottom: var(--spacing-lg);
}
.form-order-select {
  @media screen and (max-width: $mobile) {
    top: auto;
    bottom: 100%;
  }
}
.form-order-text {
  margin-top: var(--spacing-lg);
  margin-bottom: var(--spacing-sm);
}
</style>
