<template>
  <div :class="$style.wrapper">
    <label
      for="fileInput"
      :class="[
        $style.uploader,
        preview && $style.active,
        disabled && $style.disabled,
      ]"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <div
        v-if="preview.file || defaultImag"
        :class="$style.imgWrapper"
      >
        <div :class="$style.data">
          <div
            :class="$style.img"
            :style="{ backgroundImage: `url(${preview.file || defaultImag})` }"
          ></div>
          <span
            v-if="preview.name"
            :class="$style.name"
            >{{ preview.name }}</span
          >
        </div>
        <button
          :class="$style.btn"
          @click="fileEl.click()"
        >
          {{ $t("uploader.change") }}
        </button>
      </div>
      <div
        v-else
        :class="$style.empty"
      >
        <span>{{ $t("uploader.upload_avatar") }}</span>
        <IconUpload />
      </div>
      <input
        id="fileInput"
        ref="fileEl"
        type="file"
        name="file"
        :class="$style.input"
        accept=".png,.jpg,.jpeg"
        @change="onChange"
      />
    </label>
    <div
      v-if="hasLimit"
      :class="$style.caption"
    >
      {{ $t("uploader.file_limit", { size: "2mb" }) }}
    </div>
  </div>
</template>

<script lang="ts" setup>
const isDragging = ref(false);
const fileEl = ref<any>();
const preview = reactive({
  file: "",
  name: "",
});

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    img?: string;
    hasLimit?: boolean;
    defaultImag?: string | null;
  }>(),
  {
    disabled: false,
    hasLimit: true,
  },
);
let timer: ReturnType<typeof setTimeout>;
const emits = defineEmits<{
  change: [file: File];
}>();

function onChange() {
  if (!fileEl.value?.files.length) return;
  const file = fileEl.value.files[0];
  preview.file = generateURL(file as File);
  preview.name = file.name;
  emits("change", file as File);
}
function dragover(e: Event) {
  e.preventDefault();
  isDragging.value = true;
}
function dragleave() {
  isDragging.value = false;
}
function drop(e: DragEvent) {
  e.preventDefault();
  if (!e.dataTransfer) return;

  fileEl.value = e.dataTransfer;
  onChange();
  isDragging.value = false;
}
function generateURL(file: File) {
  let fileSrc = URL.createObjectURL(file);
  timer = setTimeout(() => {
    URL.revokeObjectURL(fileSrc);
  }, 1000);
  return fileSrc;
}

onMounted(() => {
  if (props.img) preview.file = props.img;
});

onBeforeUnmount(() => {
  clearTimeout(timer);
});
</script>

<style module lags="scss">
.wrapper {
  max-width: 422px;
  width: 100%;
}

.uploader {
  border-radius: var(--radius-max);
  display: flex;
  padding: var(--spacing-xs) var(--spacing-4xl) var(--spacing-xs)
    var(--spacing-xs);
  height: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px dashed var(--general-transparent-light-10);

  &:hover {
    border-color: var(--general-transparent-light-35);
  }
}

.active {
  border-color: var(--general-transparent-light-35);
}

.disabled {
  border-color: transparent;
  pointer-events: none;
}

.imgWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--spacing-xs);
  width: 100%;
}

.empty {
  text-align: center;
  width: 100%;
  color: var(--general-transparent-light-75);
  font-size: 14px;
  letter-spacing: -0.07px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-right: var(--spacing-xs);
  }
}

.img {
  width: 64px;
  height: 64px;
  margin-right: var(--spacing-xs);
  border-radius: var(--radius-max);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.name {
  overflow: hidden;
  color: var(--general-transparent-light-75);
  text-overflow: ellipsis;
  font-size: 14px;
  letter-spacing: -0.07px;
  max-width: 243px;
}

.input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  color: var(--button-primary-default);
  font-size: 12px;
  line-height: 110%;
  border: 0;
  cursor: pointer;
  background: transparent;
  letter-spacing: -0.06px;
}

.caption {
  color: var(--general-transparent-light-35);
  text-align: center;
  font-size: 12px;
  letter-spacing: -0.06px;
  margin-top: var(--spacing-md);
}
</style>
